<!--
 * @Description: 名下设备
 * @Author: ChenXueLin
 * @Date: 2021-12-22 13:39:13
 * @LastEditTime: 2022-07-12 09:17:29
 * @LastEditors: ChenXueLin
-->
<template>
  <div v-loading="loading">
    <el-form class="search-list" ref="searchForm" :model="searchForm">
      <el-form-item class="search-item--1" prop="thirdClassId">
        <el-input
          v-model="searchForm.thirdClassName"
          placeholder="商品名称"
          title="商品名称"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" prop="equipCode">
        <el-input
          v-model="searchForm.equipCode"
          placeholder="设备编号"
          title="设备编号"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--buttons">
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button class="reset" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 搜索头部 end -->
    <!-- 表格 start -->
    <section class="table-wrapper">
      <!-- :height="resizeViewHeight + 'px'" -->
      <el-table
        border
        :height="tableHeight"
        :data="tableData"
        highlight-current-row
        ref="elTable"
      >
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import { getUserEquip } from "@/api";
import listPage from "@/mixins/list-page";
import { printError } from "@/utils/util";
export default {
  name: "",
  components: {},
  props: ["activeName", "corpId", "tableHeight"],
  data() {
    return {
      searchForm: {
        thirdClassName: "", //商品名称
        equipCode: "", //设备编号
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      tableData: [],
      columnData: [
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipTypeName",
          display: true,
          fieldLabel: "商品类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixedOrMovedName",
          display: true,
          fieldLabel: "固定/移动",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称(待确认)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpProperties",
          display: true,
          fieldLabel: "客户属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipStatusName",
          display: true,
          fieldLabel: "设备状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "terStatusName",
          display: true,
          fieldLabel: "终端状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneType",
          display: true,
          fieldLabel: "场景类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installGroupName",
          display: true,
          fieldLabel: "安装组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simNo",
          display: true,
          fieldLabel: "SIM卡号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "encryptionChipCode",
          display: true,
          fieldLabel: "加密芯片号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "repairTime",
          display: true,
          fieldLabel: "维修次数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installTypeName",
          display: true,
          fieldLabel: "安装类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ] //表头
    };
  },
  mixins: [base, listPage],
  computed: {},
  watch: {
    activeName: {
      immediate: true,
      handler(val) {
        if (val == "three" && this.corpId) {
          this.queryList();
        }
      }
    },
    corpId: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal && this.activeName == "three") {
          this.queryList();
        }
      }
    }
  },
  methods: {
    async queryList() {
      try {
        this.loading = true;
        let res = await getUserEquip({
          ...this.searchForm,
          corpId: this.corpId
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 搜索
    handleSearch() {
      if (!this.corpId) {
        this.$message.warning("请选择客户");
        return;
      }
      this.searchForm.pageIndex = 1;
      this.queryList();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
