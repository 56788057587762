<!--
 * @Description: 来去电工作台
 * @Author: ChenXueLin
 * @Date: 2021-09-13 11:44:13
 * @LastEditTime: 2022-07-27 10:40:07
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <div class="header-box">
            <e6-vr-select
              style="width:300px"
              v-model="curCorpId"
              :data="corpList"
              placeholder="客户名称"
              title="客户名称"
              virtual
              remote
              :is-title="true"
              @change="curCorpIdChange"
              @filterChange="handleLoadCorpFilter"
              :props="{
                id: 'corpId',
                label: 'corpName'
              }"
            ></e6-vr-select>
            <div class="phone-content" v-if="customerNumber">
              <div class="phone-title">
                {{ callType == 1 ? "来电" : "去电" }}电话号码
              </div>
              <div class="phone">
                {{ customerNumber }}
              </div>
            </div>
            <el-button
              type="primary"
              style="margin-left:20px;"
              @click="toSavePhone"
            >
              <!--  v-if="customerNumber" -->
              保存来电至
            </el-button>
          </div>
          <div class="content-wrapper">
            <div class="detail-content">
              <div class="tab-content">
                <el-tabs v-model="activeName">
                  <el-tab-pane label="客户资料" name="one">
                    <el-form
                      label-width="120px"
                      class="corpForm"
                      :model="corpInfo"
                    >
                      <el-row>
                        <el-col :span="8">
                          <el-form-item label="客户ID：" prop="corpId">
                            {{ corpInfo.corpId }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="客户名称：" prop="corpName">
                            {{ corpInfo.corpName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="所属主体：" prop="companyName">
                            {{ corpInfo.companyName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="客户级别：" prop="corpLevelName">
                            {{ corpInfo.corpLevelName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="客户类型：" prop="corpDiffName">
                            {{ corpInfo.corpDiffName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="主体等级："
                            prop="companyLevelName"
                          >
                            {{ corpInfo.companyLevelName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="客户状态："
                            prop="corpStatusName"
                          >
                            {{ corpInfo.corpStatusName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="客户经理："
                            prop="customerManagerName"
                          >
                            {{ corpInfo.customerManagerName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="服务器：" prop="corpKey">
                            {{ corpInfo.corpKey }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="业务员：" prop="salesmanName">
                            {{ corpInfo.salesmanName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="业务员电话："
                            prop="salesmanPhone"
                          >
                            {{ corpInfo.salesmanPhone }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="来电人：" prop="peopleName">
                            <span>{{ corpInfo.peopleName }}</span>
                            <el-popover
                              placement="bottom"
                              width="300"
                              trigger="hover"
                            >
                              <div class="popover-title">更多联系电话</div>
                              <el-table
                                :data="gridData"
                                :header-row-class-name="rowClass"
                              >
                                <el-table-column
                                  width="150"
                                  property="phoneNumber"
                                  label="电话号码"
                                ></el-table-column>
                                <el-table-column
                                  width="150"
                                  property="callTime"
                                  label="来电时间"
                                ></el-table-column>
                              </el-table>
                              <i
                                class="e6-icon-abnormal_line e6Icon"
                                slot="reference"
                              ></i>
                            </el-popover>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="来电人职责："
                            prop="responsibility"
                          >
                            {{ corpInfo.responsibility }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="联系地址：" prop="corpAddress">
                            {{ corpInfo.corpAddress }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="备注：" prop="remark">
                            {{ corpInfo.remark }}
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane label="名下场景" name="two">
                    <scene-list
                      :tableHeight="tableHeight"
                      :activeName="activeName"
                      :corpId="curCorpId"
                    ></scene-list>
                  </el-tab-pane>
                  <el-tab-pane label="名下设备" name="three">
                    <equip-list
                      :tableHeight="tableHeight"
                      :activeName="activeName"
                      :corpId="curCorpId"
                    ></equip-list>
                  </el-tab-pane>
                  <el-tab-pane label="工单" name="four">
                    <work-order-list
                      :tableHeight="tableHeight"
                      :activeName="activeName"
                      :corpInfo="corpInfo"
                      :corpId="curCorpId"
                      :callType="callType"
                      :customerNumber="customerNumber"
                      :mainUniqueId="mainUniqueId"
                    ></work-order-list>
                  </el-tab-pane>
                  <el-tab-pane label="维护信息" name="eight">
                    <maintenance-info
                      :tableHeight="tableHeight"
                      :activeName="activeName"
                      :corpId="curCorpId"
                      ref="maintenanceInfo"
                    ></maintenance-info>
                  </el-tab-pane>
                  <el-tab-pane label="公司账号" name="five">
                    <company-account
                      :tableHeight="tableHeight"
                      :activeName="activeName"
                      :corpId="e6CorpId"
                    ></company-account>
                  </el-tab-pane>
                  <el-tab-pane label="立即监控" name="six">
                    <monitor-list
                      :tableHeight="tableHeight"
                      :activeName="activeName"
                      :corpId="curCorpId"
                    ></monitor-list>
                  </el-tab-pane>
                  <el-tab-pane label="通讯录" name="seven">
                    <address-book
                      :tableHeight="tableHeight"
                      :activeName="activeName"
                      :corpId="curCorpId"
                      @changeCallPhone="changeCallPhone"
                    ></address-book>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div class="table-content" v-show="activeName == 'one'">
                <div class="select-form">
                  <el-form
                    :inline="true"
                    ref="serviceTypeForm"
                    :model="serviceTypeForm"
                    :rules="serviceTypeFormRules"
                  >
                    <el-form-item label="服务类型" prop="firstServerCode">
                      <e6-vr-select
                        style="width:120px"
                        v-model="serviceTypeForm.firstServerCode"
                        :data="firstTypeList"
                        :props="{
                          id: 'codeId',
                          label: 'codeName'
                        }"
                        placeholder="一级服务类型"
                        title="一级服务类型"
                        clearable
                      ></e6-vr-select>
                    </el-form-item>
                    <el-form-item prop="secondServerCode">
                      <e6-vr-select
                        style="width:120px"
                        v-model="serviceTypeForm.secondServerCode"
                        :data="secondTypeList"
                        placeholder="二级服务类型"
                        title="二级服务类型"
                        clearable
                        :props="{
                          id: 'codeId',
                          label: 'codeName'
                        }"
                      ></e6-vr-select>
                    </el-form-item>
                    <el-form-item prop="thirdServerCode">
                      <e6-vr-select
                        style="width:120px"
                        v-model="serviceTypeForm.thirdServerCode"
                        :data="thirdTypeList"
                        placeholder="三级服务类型"
                        title="三级服务类型"
                        clearable
                        :props="{
                          id: 'codeValue',
                          label: 'codeName'
                        }"
                      ></e6-vr-select>
                    </el-form-item>
                    <el-form-item prop="fourthServiceCode">
                      <e6-vr-select
                        style="width:120px"
                        v-model="serviceTypeForm.fourthServiceCode"
                        :data="fourthTypeList"
                        placeholder="四级服务类型"
                        title="四级服务类型"
                        clearable
                        :props="{
                          id: 'codeValue',
                          label: 'codeName'
                        }"
                      ></e6-vr-select>
                    </el-form-item>
                  </el-form>
                  <el-form :inline="true" style="margin-left:15px;">
                    <el-form-item label="工作类型">
                      <e6-vr-select
                        style="width:120px"
                        v-model="workTypeCode"
                        :data="workType"
                        placeholder="工作类型"
                        title="工作类型"
                        clearable
                        @change="handleWorkType"
                      ></e6-vr-select>
                      <e6-vr-select
                        style="width:150px"
                        v-model="secondWorkCode"
                        :data="secondWorkType"
                        placeholder="工作类型"
                        title="工作类型"
                        clearable
                        :props="{
                          id: 'codeValue',
                          label: 'codeName'
                        }"
                        @change="selectSecondWorkCode"
                      ></e6-vr-select>
                    </el-form-item>
                  </el-form>
                </div>
                <div class="table-box">
                  <div class="switch-page-button" style="margin-top:15px">
                    <el-button
                      round
                      :class="[clickIndex == index ? 'activeButton' : '']"
                      v-for="(btn, index) in btnList"
                      :key="index"
                      @click="switchPage(index)"
                      >{{ btn.label }}</el-button
                    >
                  </div>
                  <!-- 设备维修 -->
                  <repair
                    :serviceTypeForm="serviceTypeForm"
                    @setLoading="setLoading"
                    v-show="secondWorkCode == 1"
                    :secondWorkCode="secondWorkCode"
                    ref="repairChild"
                    :corpInfo="corpInfo"
                    :callType="callType"
                    :customerNumber="customerNumber"
                    :mainUniqueId="mainUniqueId"
                    @iscloseWindowHandle="iscloseWindowHandle"
                    :writeForm="writeForm"
                    :serverResultCode="serverResultCode"
                    :serverDetailsForm="serverDetailsForm"
                  ></repair>
                  <!-- 设备重装 -->
                  <reinstall
                    @setLoading="setLoading"
                    :serviceTypeForm="serviceTypeForm"
                    :corpInfo="corpInfo"
                    ref="reinstallChild"
                    v-show="secondWorkCode == 2"
                    @iscloseWindowHandle="iscloseWindowHandle"
                    :callType="callType"
                    :customerNumber="customerNumber"
                    :mainUniqueId="mainUniqueId"
                    :writeForm="writeForm"
                    :serverDetailsForm="serverDetailsForm"
                  ></reinstall>
                  <!-- 设置打印信息 -->
                  <!-- <set-print
                    ref="setPrint"
                    v-show="secondWorkCode == 3 || secondWorkCode == 4"
                  ></set-print> -->
                  <!-- 下发短信 -->
                  <send-message
                    v-show="secondWorkCode == 5"
                    ref="sendForm"
                  ></send-message>
                  <!-- 咨询回复 -->
                  <reply-form
                    :serviceTypeForm="serviceTypeForm"
                    v-show="secondWorkCode == 6"
                    :serverResultCode="serverResultCode"
                    :corpInfo="corpInfo"
                    ref="replyChild"
                    @setLoading="setLoading"
                    @iscloseWindowHandle="iscloseWindowHandle"
                    :callType="callType"
                    :customerNumber="customerNumber"
                    :mainUniqueId="mainUniqueId"
                    :writeForm="writeForm"
                    :serverDetailsForm="serverDetailsForm"
                  ></reply-form>
                  <!--    @updateService="getCallServiceReq" -->

                  <div class="isFinish">
                    <el-form
                      ref="serverDetailsForm"
                      label-width="130px"
                      :model="serverDetailsForm"
                      v-show="secondWorkCode != 5"
                      :rules="serverDetailsFormRules"
                    >
                      <el-form-item prop="serverDetails" label="服务内容">
                        <el-input
                          v-model="serverDetailsForm.serverDetails"
                          placeholder="服务内容"
                          type="textarea"
                          :rows="5"
                        >
                        </el-input>
                      </el-form-item>
                    </el-form>
                    <el-radio-group
                      v-model="serverResultCode"
                      v-show="
                        ['1', '6'].indexOf(secondWorkCode) > -1 &&
                          clickIndex == 0
                      "
                    >
                      <el-radio
                        :label="item.codeValue"
                        v-for="item in serviceResultList"
                        :key="item.codeValue"
                        >{{ item.codeName }}</el-radio
                      >
                      >
                    </el-radio-group>

                    <el-button
                      type="primary"
                      @click="toSend"
                      v-if="secondWorkCode == 3 || secondWorkCode == 4"
                    >
                      下发指令
                    </el-button>
                  </div>
                  <write-form
                    :corpInfo="corpInfo"
                    :contactList="contactList"
                    :secondWorkCode="secondWorkCode"
                    v-show="
                      ((['1', '6'].indexOf(secondWorkCode) > -1 &&
                        serverResultCode == 0) ||
                        secondWorkCode == 2) &&
                        clickIndex == 0
                    "
                    ref="writeForm"
                    @updateContactList="getContactList"
                  ></write-form>
                  <questions-list
                    v-show="clickIndex == 1"
                    ref="questionsRef"
                    :questionList.sync="questionList"
                  ></questions-list>
                </div>
              </div>
            </div>
            <div class="record-box">
              <el-tabs v-model="recordName">
                <el-tab-pane label="服务详情" name="one">
                  <div class="record-item-box">
                    <record-item :recordsList="recordsList"></record-item>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <div
          class="edit-wrapper__footer"
          v-if="secondWorkCode && activeName == 'one'"
        >
          <el-button
            type="primary"
            v-if="secondWorkCode == 1 && serverResultCode == 0"
            @click="handleVerify(1)"
            >转服务单</el-button
          >
          <el-button
            type="primary"
            v-if="secondWorkCode == 1 && serverResultCode == 0"
            @click="handleVerify(2)"
            >转维修任务单</el-button
          >
          <el-button
            type="primary"
            v-if="secondWorkCode == 2"
            @click="addReinstallTask"
            >创建重装任务单</el-button
          >
          <el-button
            type="primary"
            v-if="
              ['3', '4', '6'].indexOf(secondWorkCode) > -1 ||
                (secondWorkCode == 1 && serverResultCode == 1)
            "
            @click="saveServiceDetail"
            >保存</el-button
          >
          <el-button type="primary" v-if="secondWorkCode == 5" @click="sendReq"
            >提交</el-button
          >
        </div>
      </section>
    </div>
    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      @handleClose="closeDialog"
      :corpId="corpInfo.corpId"
      :isNeedReq="true"
      :corpName="corpInfo.corpName"
      :initPhone="customerNumber"
    ></add-concat-dialog>
  </div>
</template>

<script>
import eventBus from "@/bus";
import recordItem from "@/components/workOrderManage/recordItem";
import base from "@/mixins/base";
import minxins from "./minxins";
import sceneList from "./page/sceneList.vue";
import repair from "./components/repair.vue";
import writeForm from "./components/writeForm.vue";
import questionsList from "./components/questionsList.vue";
import reinstall from "./components/reinstall.vue";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import {
  getDetailById,
  getListQuestions,
  getDetailByPhone,
  getFirstType,
  getSecondType,
  findDownList,
  getUserVehicle,
  createSms,
  getCallService,
  getMorePhone,
  getContactList
} from "@/api";
import { printError } from "@/utils/util";
import EquipList from "./page/equipList.vue";
import WorkOrderList from "./page/workOrderList.vue";
import CompanyAccount from "./page/companyAccount.vue";
import MonitorList from "./page/monitorList.vue";
import AddressBook from "./page/addressBook.vue";
import sendMessage from "./components/sendMessage.vue";
import replyForm from "./components/replyForm.vue";
import MaintenanceInfo from "./page/maintenanceInfo.vue";
import QuestionsList from "./components/questionsList.vue";
import { mapActions } from "vuex";
export default {
  name: "workbench",
  components: {
    recordItem,
    sceneList,
    repair,
    reinstall,
    EquipList,
    WorkOrderList,
    CompanyAccount,
    MonitorList,
    AddressBook,
    writeForm,
    sendMessage,
    replyForm,
    AddConcatDialog,
    MaintenanceInfo,
    QuestionsList,
    questionsList
  },
  data() {
    return {
      needClose: "", // 是否需要在保存后关闭网页
      customerNumber: "", //来电客户电话
      mainUniqueId: "", //通话id
      total: 1,
      tableHeight: 300,
      corpList: [], //客户名称下拉框
      contactList: [], // 联系人下拉框数据
      corpInfo: {
        corpId: "", // 客户Id
        corpName: "", // 客户名称
        companyName: "", // 所属主体
        corpLevelName: "", // 客户级别
        corpDiffName: "", // 客户类型
        corpStatusName: "", // 客户状态
        salesmanName: "", // 业务员
        salesmanPhone: "", // 业务员电话
        peopleName: "", // 来电人
        responsibility: "", // 来电人职责
        corpAddress: "", //联系地址
        remark: "" // 备注
      }, //客户基本信息
      recordName: "one",
      recordsList: [], //服务记录列表
      activeName: "one",
      curCorpId: "", //用户选中的客户id
      e6CorpId: "",
      firstTypeList: [], //一级服务类型下拉框数据
      secondTypeList: [], //二级服务类型下拉框数据
      thirdTypeList: [], //三级服务类型下拉框
      fourthTypeList: [], //四级服务类型下拉框
      serviceResultList: [], //服务结果枚举
      serviceTypeForm: {
        firstServerCode: "", //一级服务类型
        secondServerCode: "", //二级服务类型
        thirdServerCode: "", //三级服务类型
        fourthServiceCode: ""
      }, //服务类型form
      serviceTypeFormRules: {
        firstServerCode: [
          {
            required: true,
            message: "请选择一级服务类型",
            trigger: ["blur", "change"]
          }
        ],
        secondServerCode: [
          {
            required: true,
            message: "请选择二级服务类型",
            trigger: ["blur", "change"]
          }
        ],
        thirdServerCode: [
          {
            required: true,
            message: "请选择三级服务类型",
            trigger: ["blur", "change"]
          }
        ],
        fourthServiceCode: [
          {
            required: true,
            message: "请选择四级服务类型",
            trigger: ["blur", "change"]
          }
        ]
      },
      workType: [
        { id: "maintenance", label: "维修类" },
        { id: "setting", label: "设置类" },
        { id: "consulting", label: "咨询类" }
      ], //工作类型1
      workTypeCode: "consulting", //选中的工作类型1
      secondWorkType: [], //二级工作类型
      secondWorkCode: "6", //选中的二级工作类型
      serverResultCode: "1", //是否已完成
      isConfirm: 0,
      // 服务内容form
      serverDetailsForm: {
        serverDetails: ""
      },
      serverDetailsFormRules: {
        serverDetails: [
          {
            required: true,
            message: "请输入服务内容",
            trigger: ["blur", "change"]
          }
        ]
      },
      /*****选择场景******/
      selectSceneDialog: false, //选择场景
      gridData: [],
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "场景名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "场景类型",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "车牌颜色/地址",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "安装组合",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "商品名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "商品分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "商品类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "固定/移动",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "设备编号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "保修期始",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "保修期末",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "问题类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "问题描述",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "提醒信息",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //订单页面表头
      /*****添加联系人*******/
      callType: "",
      addContactVisible: false,
      writeForm: {},
      clickIndex: 0,
      btnList: [{ label: "咨询回复", id: 6 }],
      /*****客户回访********/
      questionList: [],
      revisitId: "",
      nowDate: new Date().setHours(new Date().getHours() + 4)
    };
  },
  mixins: [base, minxins],
  computed: {},
  watch: {
    // 监听一级服务类型选择更改
    "serviceTypeForm.firstServerCode": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          this.getSecondType(val);
        } else {
          this.secondTypeList = [];
          this.serviceTypeForm.firstServerCode = "";
          this.serviceTypeForm.secondServerCode = "";
        }
      }
    },
    // customerNumber: {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {
    //       this.serviceTypeForm.thirdServerCode = "1";
    //       this.getDetailByPhone();
    //     }
    //   }
    // },
    //
    "corpInfo.corpId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.curCorpId = val;
          this.corpList = [{ corpId: val, corpName: this.corpInfo.corpName }];
          this.getCallServiceReq();
          this.getMorePhoneReq();
          this.getContactList();
          this.$nextTick(() => {
            this.$refs.maintenanceInfo.queryList();
          });
        }
      }
    },
    "$route.query.returnTypeCode": {
      immediate: true,
      handler(val) {
        if (val) {
          if (
            this.btnList.every(item => {
              return item.label != "客户回访";
            })
          ) {
            this.btnList.push({ label: "客户回访", id: "visiteType" });
          }
          this.curCorpId = this.$route.query.corpId * 1;
          this.getDetailById();
        } else {
          this.clickIndex = 0;
          if (this.btnList.length > 1) {
            this.btnList.pop();
          }
        }
      }
    }
  },
  activated() {
    if (this.$route.params.refresh) {
      this.refreshData();
    }
  },
  created() {
    if (!this.$route.params.refresh) {
      this.refreshData();
    }
    if (this.$route.query.needClose) {
      this.toggleScreen(true);
    }
  },
  methods: {
    ...mapActions("app", ["toggleScreen"]),
    //初始化数据
    refreshData() {
      //进行回访
      this.clickIndex = 0;
      this.tableHeight = document.documentElement.clientHeight - 300;
      if (this.$route.query.needClose) {
        //弹屏
        this.mainUniqueId = this.$route.query.mainUniqueId;
        this.callType = this.$route.query.callType;
        this.customerNumber = this.$route.query.customerNumber;
        this.firstServer = this.$route.query.firstServer;
        if (this.$route.query.returnTypeCode) {
          //回访去电
          this.curCorpId = this.$route.query.corpId * 1;
          this.clickIndex = 1;
          this.revisitId = this.$route.query.revisitId
            ? this.$route.query.revisitId
            : ""; //回访id，提交问题答案时使用
          this.revisitTypeCode = this.$route.query.returnTypeCode
            ? this.$route.query.returnTypeCode
            : "";
          this.serviceTypeForm.firstServerCode = "35";
          this.serviceTypeForm.secondServerCode = this.$route.query.secondService;
          this.serviceTypeForm.thirdServerCode = "1";
          this.getListQuestions();
          this.customerNumber = this.$route.query.customerNumber;
        } else {
          //来电弹屏
          this.serviceTypeForm.thirdServerCode = "1";
          this.getDetailByPhone();
          this.needClose = this.$route.query.needClose;
        }
      }

      this.initData();
      this.getWorkType(this.workTypeCode);
      this.getListQuestions();
      // this.$nextTick(() => {
      //   this.$refs.maintenanceInfo.queryList();
      // });
    },
    //获取问题列表
    async getListQuestions() {
      try {
        this.loading = true;
        let res = await getListQuestions({
          returnTypeCode: this.$route.query.returnTypeCode
        });
        let questionList = res.data ? res.data : [];
        questionList.map(item => {
          item.answerId = "";
          item.remark = "";
        });
        this.questionList = questionList;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //选择工作类型
    handleWorkType() {
      this.secondWorkCode = "";
      this.secondWorkType = [];
      this.getWorkType(this.workTypeCode);
    },
    //选择二级工作类型
    selectSecondWorkCode() {
      let item = this.secondWorkType.find(
        item => item.codeValue == this.secondWorkCode
      );
      this.btnList[0] = item;
    },
    watchCode(code) {
      this.serverResultCode = code;
    },
    curCorpIdChange() {
      if (this.curCorpId) {
        this.getDetailById();
      }
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.curCorpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    async initData() {
      try {
        let promiseList = [
          getFirstType({ whether: 1 }),
          findDownList(["serviceType", "serviceResult", "fourthService"])
        ];
        let [firstTypeRes, downRes] = await Promise.all(promiseList);
        //问题一级类型
        this.firstTypeList = this.getFreezeResponse(firstTypeRes, {
          path: "data"
        });
        //三级服务类型
        this.thirdTypeList = this.getFreezeResponse(downRes, {
          path: "data.serviceType"
        });
        //四级服务类型
        this.fourthTypeList = this.getFreezeResponse(downRes, {
          path: "data.fourthService"
        });
        //服务结果
        this.serviceResultList = this.getFreezeResponse(downRes, {
          path: "data.serviceResult"
        });
        if (this.firstServer) {
          let firstTypeInfo = this.firstTypeList.find(item => {
            return item.codeName == this.firstServer;
          });
          this.serviceTypeForm.firstServerCode =
            firstTypeInfo && firstTypeInfo.codeId ? firstTypeInfo.codeId : "";
        }
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //获取联系人下拉框
    async getContactList() {
      if (!this.curCorpId) {
        return;
      }
      try {
        let res = await getContactList({ id: this.curCorpId });
        if (res.code === "OK") {
          this.contactList = res.data.map(item => {
            if (item.phoneList && item.phoneList.length) {
              item.contactNamePhone = `${
                item.contactName
              }(${item.phoneList.join(",")})`;
            }
            return item;
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取客服服务记录
    async getCallServiceReq() {
      try {
        let res = await getCallService({ id: this.curCorpId });
        this.recordsList = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取来电人的来电记录
    async getMorePhoneReq() {
      try {
        let res = await getMorePhone({ customerId: this.curCorpId });
        this.gridData = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 修改去电号码
    changeCallPhone(data) {
      this.customerNumber = data.phone;
      this.callType = data.callType;
    },
    //保存来电
    toSavePhone() {
      if (!this.corpInfo.corpId) {
        this.$message.warning("请先选择客户");
        return;
      }
      this.addContactVisible = true;
    },
    //关闭弹框
    closeDialog() {
      this.addContactVisible = false;
    },
    //获取二级工作类型
    async getWorkType(val) {
      try {
        let res = await findDownList([val]);
        //获取客户名称
        this.secondWorkType = res.data[val];
      } catch (error) {
        printError(error);
      }
    },
    //根据id获取客户信息
    async getDetailById() {
      try {
        let res = await getDetailById({ corpId: this.curCorpId });
        let data = res.data ? res.data : {};
        this.corpInfo = data;
        this.e6CorpId = data.e6CorpId;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //根据手机号获取客户信息
    async getDetailByPhone() {
      try {
        let res = await getDetailByPhone({ phone: this.customerNumber });
        this.corpInfo = res.data ? res.data : this.corpInfo;
        this.e6CorpId = this.corpInfo.e6CorpId;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取二级服务类型
    async getSecondType() {
      try {
        this.selectLoading = true;
        let res = await getSecondType({
          firstServerCode: this.serviceTypeForm.firstServerCode
        });
        this.secondTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //获取名下设备
    async getUserVehicle() {
      try {
        this.selectLoading = true;
        let res = await getUserVehicle({
          firstServerCode: this.serviceTypeForm.firstServerCode
        });
        this.secondTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //下发指令
    toSend() {
      window.open("http://devman.e6yun.com", "_blank");
    },
    //创建重装任务单
    addReinstallTask() {
      //服务类型的校验
      const p1 = new Promise(resolve => {
        this.$refs["serviceTypeForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      //服务内容填写的校验
      const p2 = new Promise(resolve => {
        this.$refs["serverDetailsForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      // 创建单基本信息填写的校验
      const p3 = new Promise(resolve => {
        this.$refs["writeForm"].$refs["writeForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      const p4 = new Promise(resolve => {
        if (this.btnList.length > 1) {
          if (
            !this.questionList
              .filter(item => {
                return item.mustNote;
              })
              .every(each => {
                return each.remark;
              }) &&
            this.$refs.questionsRef.issuccess == 1
          ) {
            this.$message.warning("客户回访有必填项备注未进行填写");
            return;
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
      Promise.all([p1, p2, p3, p4]).then(() => {
        this.writeForm = this.$refs.writeForm.writeForm;
        this.$refs.reinstallChild.toTaskOrder();
      });
    },
    //点击保存
    saveServiceDetail() {
      if (!this.corpInfo.corpId) {
        this.$message.warning("请选择客户名称");
        return;
      }
      if (this.secondWorkCode == 1) {
        //维修并且是已完成
        this.handleVerify();
      }
      if (this.secondWorkCode == 3 || this.secondWorkCode == 4) {
        //3:设置打印信息4:疲劳驾驶消除
        this.$refs.serviceTypeForm.validate(valid => {
          if (valid) {
            this.$refs.serverDetailsForm.validate(valid1 => {
              if (valid1) {
                if (
                  this.btnList.length > 1 &&
                  !this.questionList
                    .filter(item => {
                      return item.mustNote;
                    })
                    .every(each => {
                      return each.remark;
                    }) &&
                  this.$refs.questionsRef.issuccess == 1
                ) {
                  {
                    this.$message.warning("客户回访有必填项备注未进行填写");
                    return;
                  }
                }
                //调用添加服务记录
                this.addServiceRecordReq(
                  "",
                  this.serverDetailsForm.serverDetails
                );
              }
            });
          }
        });
      }
      //提交咨询服务单
      if (this.secondWorkCode == 6) {
        this.handleVerify();
      }
    },
    //处理提交的时候多个表单校验
    handleVerify(submitType) {
      if (!this.corpInfo.corpId) {
        this.$message.warning("请选择客户名称");
        return;
      }
      //服务类型的校验
      const p1 = new Promise(resolve => {
        this.$refs["serviceTypeForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      //服务内容填写的校验
      const p2 = new Promise(resolve => {
        this.$refs["serverDetailsForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      // 创建单基本信息填写的校验
      const p3 = new Promise(resolve => {
        this.$refs["writeForm"].$refs["writeForm"].validate(valid => {
          // 如果是维修或者重装，要求完成时间不能小于当前时间+4小时
          if (
            this.secondWorkCode == 2 ||
            (this.secondWorkCode == 1 &&
              this.serverResultCode == 0 &&
              this.$refs["writeForm"].writeForm.expecttime < this.nowDate)
          ) {
            this.$message.warning("要求完成时间不能小于当前时间+4小时");
            return;
          }
          if (valid) resolve();
        });
      });
      const p4 = new Promise(resolve => {
        if (this.btnList.length > 1) {
          if (
            !this.questionList
              .filter(item => {
                return item.mustNote;
              })
              .every(each => {
                return each.remark;
              }) &&
            this.$refs.questionsRef.issuccess == 1
          ) {
            this.$message.warning("客户回访有必填项备注未进行填写");
            return;
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });

      if (this.serverResultCode == 0) {
        //未完成
        Promise.all([p1, p2, p3, p4]).then(() => {
          this.writeForm = this.$refs.writeForm.writeForm;
          //报修单
          if (this.secondWorkCode == 1) {
            //submitType：1转服务单2转任务单
            this.$refs.repairChild.toTaskOrder(submitType);
          }
          if (this.secondWorkCode == 6) {
            //咨询回复单
            this.$refs.replyChild.addReply(this.writeForm);
          }
        });
      } else {
        //已完成
        Promise.all([p1, p2, p4]).then(() => {
          //调用添加服务记录
          this.addServiceRecordReq(
            "",
            this.serverDetailsForm.serverDetails,
            ""
          );
        });
      }
    },
    //下发短信
    sendReq() {
      this.$refs.sendForm.$refs.addForm.validate(valid => {
        if (valid) {
          this.addReq();
        }
      });
    },
    //下发短信请求
    async addReq() {
      try {
        this.loading = true;
        let res = await createSms(this.$refs.sendForm.addForm);
        if (res.code == "OK") {
          this.$message.success("下发成功");
          if (this.iscloseWindowHandle) {
            this.iscloseWindowHandle();
          } else {
            this.$emit("iscloseWindowHandle");
          }
          this.toPage();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击筛选框
    switchPage(index) {
      this.clickIndex = index;
    },
    /********选择场景*********/
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    },
    rowClass() {
      return "popper-table-tr";
    },
    setLoading(val) {
      this.loading = val;
    }
  },
  destroyed() {
    eventBus.$off("UPDATE_VISITE_RESULT");
  }
};
</script>
<style lang="scss" scoped>
.content-view-wrapper {
  height: 100%;
  box-sizing: border-box;
  .content-view {
    height: 100%;
    .edit-wrapper {
      height: 100%;
      .edit-wrapper__body {
        height: calc(100% - 55px);
      }
    }
  }
}
.edit-wrapper__footer {
  padding: 10px 0;
  border-top: 1px solid #edf0f5;
  text-align: center;
  box-shadow: 0 0 3px #e6ebf2;
  .el-button {
    height: 36px;
    padding-left: 35px;
    padding-right: 35px;
    margin-left: 0;
  }
}
.header-box {
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  .phone-content {
    width: 200px;
    height: 32px;
    margin-left: 15px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    display: flex;
    .phone-title {
      padding-left: 5px;
      padding-right: 5px;
      box-sizing: border-box;
      line-height: 32px;
      text-align: center;
      font-size: 12px;
      border-right: 1px solid #dcdfe6;
      color: rgb(96, 98, 102);
      background: #f5f7fa;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .phone {
      padding-left: 5px;
      padding-right: 5px;
      box-sizing: border-box;
      text-align: center;
      line-height: 32px;
      font-size: 12px;
      color: rgb(72, 73, 76);
    }
  }
}
.content-wrapper {
  width: 100%;
  display: flex;
  height: calc(100% - 50px);
  overflow-y: auto;
  .detail-content {
    width: calc(100% - 300px);
    height: 100%;
    border-right: 1px solid #edf0f5;
    .tab-content {
      /deep/.el-tabs__item {
        height: 40px;
        line-height: 40px;
      }
    }
    /deep/.table-content {
      padding-bottom: 20px;
      box-sizing: border-box;
      .select-form {
        width: 100%;
        height: 65px;
        border-top: 1px solid #edf0f5;
        border-bottom: 1px solid #edf0f5;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px;
        box-sizing: border-box;
        .el-form {
          flex-shrink: 0;
        }
        .el-form-item {
          margin-bottom: 0px;
          margin-right: 5px;
        }
      }
      .table-box {
        .tabel-search {
          display: flex;
          justify-content: space-between;
          .el-form {
            margin-top: 10px;
            margin-left: 10px;
            .el-form-item--mini.el-form-item,
            .el-form-item--small.el-form-item {
              margin-bottom: 10px;
            }
          }
          .btns {
            margin-top: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .record-content {
    width: 300px;
    .record-title {
      background: #fafbfc;
      border-top: 1px solid #edf0f5;
      border-bottom: 1px solid #edf0f5;
      padding-left: 20px;
      box-sizing: border-box;
      width: 100%;
      height: 42px;
      font-size: 14px;
      line-height: 40px;
      color: rgb(43, 44, 46);
    }
  }
}
.isFinish {
  padding: 0px 20px 20px;
  padding-bottom: 0px;
  box-sizing: border-box;
  .el-radio-group {
    margin-bottom: 20px;
    margin-left: 50px;
  }
  .el-textarea {
    width: 400px;
  }
}
/deep/.corpForm {
  margin-top: 20px;
  margin-bottom: 15px;
  .el-form-item {
    margin-bottom: 0px;
  }
}
.el-popper {
  .popover-title {
    font-size: 14px;
    margin-bottom: 10px;
    color: rgb(48, 49, 51);
  }
  .el-table .el-table__row:nth-child(2n) td {
    background: #fff !important;
  }
}
/deep/.el-table .popper-table-tr th {
  background: #fff !important;
}
.record-box {
  width: 300px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 20px;
}
</style>
