<!--
 * @Description: 通讯录
 * @Author: ChenXueLin
 * @Date: 2021-12-22 13:39:13
 * @LastEditTime: 2022-07-12 09:17:18
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <!-- 表格 start -->
    <section class="table-wrapper">
      <el-table
        border
        :height="tableHeight + 50"
        :data="tableData"
        highlight-current-row
        ref="elTable"
        :row-key="
          row => {
            return row.contactId;
          }
        "
      >
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="column.fieldName === 'phoneList'">
              <el-button
                :title="`拨打电话${phoneItem}`"
                type="text"
                @click="handleCallPhone(phoneItem)"
                v-for="(phoneItem, idx) in row.phoneList"
                :key="idx"
                >{{ phoneItem }}</el-button
              >
            </div>
            <span v-else-if="column.fieldName === 'contactTypeName'">
              {{ getContactTypeName(row.contactTypeName) }}
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import { getAddressBook, callPhone } from "@/api";
import listPage from "@/mixins/list-page";
import { printError } from "@/utils/util";
export default {
  name: "",
  components: {},
  props: ["activeName", "corpId", "tableHeight"],
  data() {
    return {
      PhoneExt: Cookies.get("PhoneExt"),
      searchForm: {
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      tableData: [],
      columnData: [
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "联系人名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactTypeName",
          display: true,
          fieldLabel: "联系人职责",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phoneList",
          display: true,
          fieldLabel: "联系人电话",
          width: 220,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "地址",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ] //名下场景表头
    };
  },
  mixins: [base, listPage],
  computed: {},
  watch: {
    activeName: {
      immediate: true,
      handler(val) {
        if (val == "seven" && this.corpId) {
          this.queryList();
        }
      }
    },
    corpId: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal && this.activeName == "seven") {
          this.queryList();
        }
      }
    }
  },
  methods: {
    handleCallPhone(phone) {
      if (this.PhoneExt === "null") {
        this.$message.warning("您没有呼叫平台坐席号，无法外呼电话。");
        return;
      }
      this.$confirm("是否确认拨打此电话？", "拨打电话", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.callPhone(phone);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 获取联系人职责
    getContactTypeName(contactTypeName) {
      // console.log(contactTypeName.join("、"));
      if (!contactTypeName || !contactTypeName.length) {
        return "";
      }
      return contactTypeName.join("、");
    },
    async callPhone(phone) {
      this.loading = true;
      try {
        let res = await callPhone({
          customerNumber: phone,
          cno: this.PhoneExt
        });
        if (res.code === "OK") {
          this.$message.success("呼叫成功，请注意您的手机电话。");
          this.$emit("changeCallPhone", {
            callType: 2,
            phone
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    async queryList() {
      try {
        this.loading = true;
        let res = await getAddressBook({
          ...this.searchForm,
          corpId: this.corpId
        });
        let tableData = res.data.array;
        tableData.map((item, idx) => {
          item.id =
            idx + (this.searchForm.pageIndex - 1) * this.searchForm.pageSize;
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
