<!--
 * @Description: 问题列表
 * @Author: ChenXueLin
 * @Date: 2022-04-26 18:02:29
 * @LastEditTime: 2022-07-11 10:09:45
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="question-content">
    <el-radio-group
      v-model="issuccess"
      style="margin-bottom:15px;margin-top:5px;"
    >
      <el-radio :label="1">成功回访</el-radio>
      <el-radio :label="2">未成功回访</el-radio>
    </el-radio-group>
    <div v-for="(item, index) in questionList" :key="item.id">
      <div :class="[{ required: item.mustNote == 1 }, 'title']">
        <div>{{ index + 1 }}、{{ item.questionContext }}</div>
        <span class="count" v-if="index == 0"
          >{{ hasWrite }}/{{ questionList.length }}</span
        >
      </div>
      <div class="radio-content">
        <el-radio-group v-model="item.answerId">
          <el-radio
            :label="each.answerId"
            v-for="each in item.revisitQuestionAnswer"
            :key="each.answerId"
            >{{ each.answerContext }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="textarea-content">
        <el-input
          placeholder="备注"
          type="textarea"
          v-model="item.remark"
          maxlength="100"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { submitQuestion } from "@/api";
import { printError } from "@/utils/util";
import eventBus from "@/bus";
export default {
  name: "questionsList",
  components: {},
  data() {
    return {
      issuccess: 1,
      fixIds: []
    };
  },
  props: ["questionList", "revisitId"],
  mixins: [],
  computed: {
    hasWrite() {
      let count = this.questionList.filter(item => {
        return item.answerId || item.remark;
      }).length;
      return count;
    }
  },
  watch: {},
  methods: {
    //提交回访
    handleSubmit() {
      let questionList = this.questionList;
      if (
        !questionList
          .filter(item => {
            return item.mustNote;
          })
          .every(each => {
            return each.remark;
          })
      ) {
        this.$message.warning("有必填项备注未进行填写");
        return;
      }
    },
    //提交回访请求
    async submitQuestionReq() {
      let returnDetailReqVOList = [];
      let revisitTimeTimeStamp = new Date().getTime();
      this.questionList.map(item => {
        if (item.answerId || item.remark) {
          returnDetailReqVOList.push(item);
        }
      });
      try {
        this.dialogLoading = true;
        // let res =
        await submitQuestion({
          revisitId: this.$route.query.revisitId,
          issuccess: this.issuccess,
          returnDetailReqVOList,
          fixIds: this.fixIds,
          revisitTimeTimeStamp
        });
        // if (res.code == "OK") {
        //   this.$message.success("提交成功");
        // }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    }
  },
  created() {
    eventBus.$on("GET_FIXID", ids => {
      this.fixIds = ids;
    });
    eventBus.$on("UPDATE_VISITE_RESULT", () => {
      if (!this.questionList.length) return;
      this.submitQuestionReq();
    });
  }
};
</script>
<style lang="scss" scoped>
.question-content {
  width: 550px;
  padding: 0px 20px 20px 20px;
  margin-left: 70px;
  box-sizing: border-box;
  .title {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    // & > div {
    //   width: 355px;
    // }
    & > span {
      flex-shrink: 0;
      color: #e8a950;
    }
  }
  .required {
    position: relative;
    &::before {
      position: absolute;
      left: -10px;
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .radio-content {
    margin-bottom: 15px;
  }
  .textarea-content {
    margin-bottom: 15px;
  }
  .result-content {
    margin-bottom: 30px;
    .answer {
      margin-bottom: 10px;
    }
  }
  .el-input {
    width: 300px;
  }
}
</style>
