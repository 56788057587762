<!--
 * @Description: 立即监控
 * @Author: ChenXueLin
 * @Date: 2021-12-22 13:39:13
 * @LastEditTime: 2022-07-12 09:17:48
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-form class="search-list" ref="searchForm" :model="searchForm">
      <el-form-item class="search-item--1" prop="regName">
        <el-input
          v-model="searchForm.regName"
          placeholder="终端名称"
          title="终端名称"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" prop="commids">
        <el-input
          v-model="searchForm.commids"
          placeholder="中心识别码"
          title="中心识别码"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" prop="terStatus">
        <e6-vr-select
          v-model="searchForm.terStatus"
          :data="terminalStatusEnum"
          placeholder="终端状态"
          title="终端状态"
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item class="search-item--buttons">
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button class="reset" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 搜索头部 end -->
    <!-- 表格 start -->
    <section class="table-wrapper">
      <el-table
        border
        :height="tableHeight"
        :data="tableData"
        highlight-current-row
        ref="elTable"
      >
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import { monitor, findDownList } from "@/api";
import listPage from "@/mixins/list-page";
import { printError } from "@/utils/util";
export default {
  name: "",
  components: {},
  props: ["activeName", "corpId", "tableHeight"],
  data() {
    return {
      searchForm: {
        regName: "", //终端名称
        terStatus: "", //终端状态
        commids: "", //中心识别码
        pageIndex: 1,
        pageSize: 20
      },
      terminalStatusEnum: [], //终端状态列表
      total: 0,
      tableData: [],
      columnData: [
        {
          fieldName: "regName",
          display: true,
          fieldLabel: "终端名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "terStatusName",
          display: true,
          fieldLabel: "终端状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "gpsTime",
          display: true,
          fieldLabel: "GPS时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "speed",
          display: true,
          fieldLabel: "速度",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "odometer",
          display: true,
          fieldLabel: "里程",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "status",
          display: true,
          fieldLabel: "状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "lon",
          display: true,
          fieldLabel: "经度",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "lat",
          display: true,
          fieldLabel: "纬度",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "odometer",
          display: true,
          fieldLabel: "里程",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "heading",
          display: true,
          fieldLabel: "方向",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "placeName",
          display: true,
          fieldLabel: "地点名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "roadName",
          display: true,
          fieldLabel: "道路名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ] //名下场景表头
    };
  },
  mixins: [base, listPage],
  computed: {},
  watch: {
    activeName: {
      immediate: true,
      handler(val) {
        if (val == "six" && this.corpId) {
          this.queryList();
        }
      }
    },
    corpId: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal && this.activeName == "six") {
          this.queryList();
        }
      }
    }
  },
  methods: {
    // 获取基础信息(枚举)
    async initData() {
      try {
        let promiseList = [findDownList(["terminalStatus"])];
        let [findDownListRes] = await Promise.all(promiseList);
        this.terminalStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.terminalStatus"
        });
      } catch (error) {
        printError(error);
      }
    },
    async queryList() {
      try {
        this.loading = true;
        let res = await monitor({
          ...this.searchForm,
          corpId: this.corpId
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 搜索
    handleSearch() {
      if (!this.corpId) {
        this.$message.warning("请选择客户");
        return;
      }
      this.searchForm.pageIndex = 1;
      this.queryList();
    }
  },
  created() {
    this.initData();
  }
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
