<!--
 * @Description: 维护信息
 * @Author: ChenXueLin
 * @Date: 2022-04-22 14:54:57
 * @LastEditTime: 2022-08-25 15:34:26
 * @LastEditors: ChenXueLin
-->
<template>
  <div v-bind="$attrs">
    <el-form class="search-list" ref="searchForm" :model="searchForm">
      <el-form-item class="search-item--1" prop="taskItemId">
        <el-input
          v-model="searchForm.taskItemId"
          placeholder="工单ID"
          title="工单ID"
          maxlength="10"
          @input="
            searchForm.taskItemId = searchForm.taskItemId.replace(
              /[^0-9-]+/,
              ''
            )
          "
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" prop="sceneName">
        <el-input
          v-model="searchForm.sceneName"
          placeholder="场景名称"
          title="场景名称"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--2" prop="createTime">
        <e6-date-picker
          popper-class="no-atTheMoment"
          label="维护时间"
          ref="effectiveDatePicker"
          v-model="searchForm.createTime"
          title="维护时间"
          :picker-options="pickerOptions('searchForm.createTime')"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="维护时间（始）"
          end-placeholder="维护时间（止）"
        ></e6-date-picker>
      </el-form-item>
      <el-form-item class="search-item--buttons">
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button class="reset" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 搜索头部 end -->
    <!-- 表格 start -->
    <section class="table-wrapper">
      <!-- :height="resizeViewHeight + 'px'" -->
      <el-table
        border
        :height="tableHeight"
        :data="tableData"
        highlight-current-row
        ref="elTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="column.fieldName === 'workNo'">
              <el-button type="text" @click="toTaskDetail(row)">{{
                row[column.fieldName]
              }}</el-button>
            </div>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="120">
          <template slot-scope="scope">
            <e6-td-operate
              :data="getOperateList()"
              @command="handleOperate($event, scope.row)"
            ></e6-td-operate>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import { getMaintain, getMaintainFeeList } from "@/api";
import listPage from "@/mixins/list-page";
import { printError } from "@/utils/util";
import eventBus from "@/bus";
export default {
  name: "maintenanceInfo",
  components: {},
  props: ["activeName", "corpId", "tableHeight"],
  data() {
    return {
      searchForm: {
        taskItemId: "", //工单ID
        sceneName: "", //场景名称
        createTime: [],
        startCreateTime: "",
        endCreateTime: "",
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      tableData: [],
      columnData: [
        {
          fieldName: "taskItemId",
          display: true,
          fieldLabel: "工单ID",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneNames",
          display: true,
          fieldLabel: "任务项状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "regName",
          display: true,
          fieldLabel: "系统场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixEngineerName",
          display: true,
          fieldLabel: "维护人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixDate",
          display: true,
          fieldLabel: "维护时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixAddress",
          display: true,
          fieldLabel: "维护地点",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "recordPerson",
          display: true,
          fieldLabel: "记录人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installCodeNo",
          display: true,
          fieldLabel: "安装单编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "linkPerson",
          display: true,
          fieldLabel: "联系人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeStr",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "checkResultName",
          display: true,
          fieldLabel: "是否审核",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstQuesName",
          display: true,
          fieldLabel: "一级问题类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondQuesName",
          display: true,
          fieldLabel: "二级问题类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "quesName",
          display: true,
          fieldLabel: "问题描述",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "quesReason",
          display: true,
          fieldLabel: "诊断问题",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "solution",
          display: true,
          fieldLabel: "处理方案",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeInfo",
          display: true,
          fieldLabel: "费用信息",
          width: 230,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ] //表头
    };
  },
  mixins: [base, listPage],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreateTime = val[0] || "";
        this.searchForm.endCreateTime = val[1] || "";
      }
    }
  },
  methods: {
    //获取15天前时间戳
    getFifFormatDate() {
      var fiftingTime = new Date().getTime() - 24 * 60 * 60 * 1000 * 15;
      // var fifting = new Date(fiftingTime);
      // var fiftingDate =
      //   fifting.getFullYear() +
      //   "-" +
      //   (fifting.getMonth() > 8
      //     ? fifting.getMonth() + 1
      //     : "0" + (fifting.getMonth() + 1)) +
      //   "-" +
      //   (fifting.getDate() > 9 ? fifting.getDate() : "0" + fifting.getDate());
      console.log(fiftingTime, "fiftingDate");
      this.searchForm.startCreateTime = fiftingTime;
      return fiftingTime;
    },

    //勾选
    handleSelectionChange(columns) {
      let selectFixIds = columns.map(item => item.fixId);
      this.$nextTick(() => {
        eventBus.$emit("GET_FIXID", selectFixIds);
      });
    },
    // 搜索
    handleSearch() {
      if (!this.corpId) {
        this.$message.warning("请选择客户");
        return;
      }
      this.searchForm.pageIndex = 1;
      this.queryList();
    },
    async queryList() {
      try {
        this.loading = true;
        let res = await getMaintain({
          ...this.searchForm,
          corpId: this.corpId
        });
        let tableData = res.data.array;
        tableData.map(item => {
          item.feeInfo = "";
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "获取费用",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.getFee(row);
      }
    },
    //获取费用
    async getFee(row) {
      try {
        let res = await getMaintainFeeList({
          taskItemId: row.taskItemId,
          companyId: this.corpId,
          pageIndex: 1,
          pasgeSize: 99
        });
        let feeInfo = res.data.array.map(item => {
          return `${item.feeName}[${item.taxGiveTypeName},${item.currencyName},${item.paidIn}]`;
        });
        row.feeInfo = feeInfo.join(";");
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    let startCreateTime = new Date().getTime() - 24 * 60 * 60 * 1000 * 15;
    let endCreateTime = new Date().getTime();
    this.searchForm.createTime = [startCreateTime, endCreateTime];
  }
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
/deep/ .add-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-form {
      .el-input {
        width: 260px;
      }
      .el-textarea {
        width: 260px;
      }
    }
  }
}
</style>
