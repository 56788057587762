<!--
 * @Description: 服务详情列表
 * @Author: ChenXueLin
 * @Date: 2021-08-25 15:48:03
 * @LastEditTime: 2022-06-20 17:08:56
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div class="record-item" v-for="(item, index) in recordsList" :key="index">
      <div class="title">第{{ item.sort }}次服务详情如下:</div>
      <div class="item-content">
        <div v-if="item.phoneType">
          <i class="e6-icon-phone"></i>
          <span class="label">{{ item.phoneType }}:</span>
        </div>
        <div class="value">{{ item.phoneNumber }}</div>
      </div>
      <div class="item-content">
        <div>
          <i class="e6-icon-examine_fill"></i>
          <span class="label">联系人类型:</span>
        </div>
        <div class="value">{{ item.isEmployeeName }}</div>
      </div>
      <div class="item-content">
        <div>
          <i class="e6-icon-service-provider_fill"></i>
          <span class="label">联系人:</span>
        </div>
        <div class="value">{{ item.phoneCallName }}</div>
      </div>
      <div class="item-content">
        <div>
          <i class="e6-icon-time_line"></i>
          <span class="label">联系时间:</span>
        </div>
        <div class="value">{{ item.serverTimeString }}</div>
      </div>
      <div class="item-content">
        <div>
          <i class="e6-icon-user_line"></i>
          <span class="label">创建人:</span>
        </div>
        <div class="value">{{ item.serverName }}</div>
      </div>
      <div class="item-content">
        <div>
          <i class="e6-icon-time_line"></i>
          <span class="label">创建时间:</span>
        </div>
        <div class="value">{{ item.createdTimeString }}</div>
      </div>
      <div class="item-content">
        <div>
          <i class="e6-icon-order_fill"></i>
          <span class="label">服务内容:</span>
        </div>
        <div class="value">{{ item.serverDetails }}</div>
      </div>
      <div class="item-content">
        <div>
          <i class="e6-icon-order_fill"></i>
          <span class="label">工单ID:</span>
        </div>
        <div class="value">{{ item.itemIdList }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recordItem",
  components: {},
  data() {
    return {};
  },
  props: {
    recordsList: {
      type: Array,
      default: () => []
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>
<style lang="scss" scoped>
.record-item {
  margin: 0 auto;
  margin-top: 10px;
  width: 270px;
  // height: 210px;
  background: #f0f3f8;
  border-radius: 20px;
  padding: 15px;
  box-sizing: border-box;
  .title {
    font-size: 14px;
    color: #28292b;
  }
  .item-content {
    display: flex;
    margin-top: 4px;
    & > div {
      &:nth-child(1) {
        flex-shrink: 0;
      }
    }
    [class^="e6-icon"] {
      color: #787b80;
    }
    .label {
      color: #4b4e53;
      flex-shrink: 0;
      font-size: 14px;
    }
    .value {
      width: 185px;
      color: #787b80;
      margin-left: 5px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
