<!--
 * @Description: 咨询类form
 * @Author: ChenXueLin
 * @Date: 2022-01-04 10:28:01
 * @LastEditTime: 2022-04-21 16:07:35
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div class="edit-content" style="margin-bottom: 20px">
      <div class="associated-info">
        <div class="right-button">
          <el-button type="primary" @click="addScene">
            添加任务项
          </el-button>
          <el-button type="primary" @click="handleDelete">
            删除任务项
          </el-button>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="sceneName"
          label="场景名称"
          width="160"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.show">{{ row.sceneName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="sceneTypeName"
          label="场景类型"
          width="160"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.show">{{ row.sceneTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="thirdClassName"
          label="商品名称"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="firstClassName"
          label="商品分类"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="secondClassName"
          label="商品开票名称"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="virtualTypeName"
          label="商品类型"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="equipCode"
          label="设备编号"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="realNo"
          label="SIM卡号"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="commids"
          label="中心识别码"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </div>

    <!-- 选择场景 -->
    <select-scene
      :selectSceneDialog="selectSceneDialog"
      @handleClose="handleClose"
      @getData="getData"
      :corpId="corpInfo.corpId"
    ></select-scene>
  </div>
</template>

<script>
import base from "@/mixins/base";
import SelectScene from "@/components/workOrderManage/selectScene.vue";
import { addReplyService } from "@/api";
import { printError } from "@/utils/util";
import minxins from "../minxins";

export default {
  name: "replyForm",
  components: { SelectScene },
  data() {
    return {};
  },
  props: [
    "corpInfo",
    "serviceTypeForm",
    "callType",
    "customerNumber",
    "mainUniqueId",
    "serverResultCode",
    "serverDetailsForm",
    "writeForm"
  ],
  mixins: [base, minxins],
  computed: {},
  watch: {},
  methods: {
    //创建咨询回复服务单请求
    async addReply(writeForm) {
      try {
        this.$emit("setLoading", true);
        console.log(this.writeForm, "writeForm");
        let res = await addReplyService({
          ...writeForm,
          serviceContent: this.serverDetailsForm.serverDetails, //兼容一下不同接口的字段
          corpId: this.corpInfo.corpId,
          corpName: this.corpInfo.corpName,
          solveRpid:
            writeForm.solueIsDept == 1
              ? writeForm.departmentId.join("")
              : writeForm.solveRpid,
          items: this.tableData
        });
        if (res.code == "OK") {
          //调用添加服务记录
          this.addServiceRecordReq(
            writeForm.expecttime,
            this.serverDetailsForm.serverDetails,
            res.data.id
          );
        }
      } catch (error) {
        printError(error);
      } finally {
        this.$emit("setLoading", false);
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
}
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.isFinish {
  padding: 20px;
  box-sizing: border-box;
}
.write-box {
  /deep/.el-form {
    .e6-vr-select {
      width: 260px !important;
    }
    .el-form-item {
      .el-input {
        width: 260px;
      }
      .el-textarea {
        width: 400px;
      }
    }
  }
}
</style>
