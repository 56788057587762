var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"edit-content",staticStyle:{"margin-bottom":"20px"}},[_c('div',{staticClass:"associated-info"},[_c('div',{staticClass:"right-button"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addScene}},[_vm._v("\n          添加任务项\n        ")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDelete}},[_vm._v("\n          删除任务项\n        ")])],1)]),_vm._v(" "),_c('el-table',{ref:"multipleTable",attrs:{"data":_vm.tableData,"highlight-current-row":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50","selectable":_vm.selectable}}),_vm._v(" "),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"sceneName","label":"场景名称","width":"160","fixed":"","align":"center","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.show)?_c('span',[_vm._v(_vm._s(row.sceneName))]):_vm._e()]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"secondClassName","label":"安装组合","width":"160","align":"center","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.show2)?_c('span',[_vm._v(_vm._s(row.secondClassName))]):_vm._e()]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"thirdClassName","label":"已安装商品","width":"160","align":"center","header-align":"center"}}),_vm._v(" "),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"firstClassName","label":"商品分类","width":"160","align":"center","header-align":"center"}}),_vm._v(" "),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"equipCode","label":"设备编号","width":"160","align":"center","header-align":"center"}}),_vm._v(" "),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"virtualTypeName","label":"类型","width":"160","align":"center","header-align":"center"}}),_vm._v(" "),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"newSceneName","width":"160","label":"新场景名称","align":"center","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.show)?_c('el-input',{on:{"input":function($event){scope.row.newSceneName = scope.row.newSceneName.replaceAll(
                ' ',
                ''
              )}},model:{value:(scope.row.newSceneName),callback:function ($$v) {_vm.$set(scope.row, "newSceneName", $$v)},expression:"scope.row.newSceneName"}}):_vm._e()]}}])})],1)],1),_vm._v(" "),_c('select-scene',{attrs:{"unknownScene":_vm.unknownScene,"addType":1,"corpId":_vm.corpInfo.corpId,"selectSceneDialog":_vm.selectSceneDialog},on:{"handleClose":_vm.handleClose,"getData":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }