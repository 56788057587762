<!--
 * @Description: 名下场景
 * @Author: ChenXueLin
 * @Date: 2021-12-22 13:39:13
 * @LastEditTime: 2022-07-12 09:17:53
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-form class="search-list" ref="searchForm" :model="searchForm">
      <el-form-item class="search-item--1" prop="sceneName">
        <el-input
          v-model="searchForm.sceneName"
          placeholder="场景名称"
          title="场景名称"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" prop="equipCode">
        <el-input
          v-model="searchForm.equipCode"
          placeholder="设备编号"
          title="设备编号"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--buttons">
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button class="reset" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 搜索头部 end -->
    <!-- 表格 start -->
    <section class="table-wrapper">
      <!-- :height="resizeViewHeight + 'px'" -->
      <el-table
        border
        :height="tableHeight"
        :data="tableData"
        highlight-current-row
        ref="elTable"
      >
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="index < 5 && !row.isDisplayPublicfield"></span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import { getUserVehicle } from "@/api";
import listPage from "@/mixins/list-page";
import { printError } from "@/utils/util";
export default {
  name: "sceneList",
  components: {},
  props: ["activeName", "corpId", "tableHeight"],
  data() {
    return {
      searchForm: {
        sceneName: "", //场景名称
        equipCode: "", //设备编号
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      tableData: [],
      columnData: [
        {
          fieldName: "rowNum",
          display: true,
          fieldLabel: "序号",
          width: 60,
          disabled: false,
          fixed: "left",
          align: "center"
        },
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "regName",
          display: true,
          fieldLabel: "系统场景名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneTypeName",
          display: true,
          fieldLabel: "场景类型",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "realNo",
          display: true,
          fieldLabel: "SIM卡号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "terStatusName",
          display: true,
          fieldLabel: "终端状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "已安装商品",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ] //名下场景表头
    };
  },
  mixins: [base, listPage],
  computed: {},
  watch: {
    activeName: {
      immediate: true,
      handler(val) {
        if (val == "two" && this.corpId) {
          this.queryList();
        }
      }
    },
    corpId: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal && this.activeName == "two") {
          this.queryList();
        }
      }
    }
  },
  methods: {
    async queryList() {
      try {
        this.loading = true;
        let res = await getUserVehicle({
          ...this.searchForm,
          corpId: this.corpId
        });
        this.tableData = this.getTableDataAddedIsDisplayPublicfield(
          res.data.array
        );
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 搜索
    handleSearch() {
      if (!this.corpId) {
        this.$message.warning("请选择客户");
        return;
      }
      this.searchForm.pageIndex = 1;
      this.queryList();
    },
    // 洗一下数据 --> 是否展示公共字段
    getTableDataAddedIsDisplayPublicfield(data) {
      if (!data || !data.length) return;
      let list = JSON.parse(JSON.stringify(data));
      let index = -1;
      let firstequip = list[0];
      let hasMainEquip = false;
      return list.map((item, itemIndex) => {
        item.isDisplayPublicfield = index !== item.rowNum;

        // 如果该组合没有主机 则在第一个配件显示详情按钮
        if (index !== item.rowNum || itemIndex === list.length - 1) {
          this.$set(firstequip, "disDetailBtn", !hasMainEquip);
          firstequip = item;
          hasMainEquip = false;
        }
        if (item.virtualType === 1) {
          hasMainEquip = true;
        }

        index = item.rowNum;
        return item;
      });
    }
  },

  created() {}
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
