<!--
 * @Description: 设备重装
 * @Author: ChenXueLin
 * @Date: 2021-12-31 15:59:34
 * @LastEditTime: 2022-08-25 15:06:25
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div class="edit-content" style="margin-bottom: 20px">
      <div class="associated-info">
        <div class="right-button">
          <el-button type="primary" @click="addScene">
            添加任务项
          </el-button>
          <el-button type="primary" @click="handleDelete">
            删除任务项
          </el-button>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="50"
          :selectable="selectable"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="sceneName"
          label="场景名称"
          width="160"
          fixed
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.show">{{ row.sceneName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="secondClassName"
          label="安装组合"
          width="160"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.show2">{{ row.secondClassName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="thirdClassName"
          label="已安装商品"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="firstClassName"
          label="商品分类"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="equipCode"
          label="设备编号"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="virtualTypeName"
          label="类型"
          width="160"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="newSceneName"
          width="160"
          label="新场景名称"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.newSceneName"
              @input="
                scope.row.newSceneName = scope.row.newSceneName.replaceAll(
                  ' ',
                  ''
                )
              "
              v-if="scope.row.show"
            ></el-input>
          </template>
        </el-table-column>
        <!-- <el-table-column
          show-overflow-tooltip
          prop="newSceneType"
          label="新场景类型"
          width="160"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <e6-vr-select
              v-if="scope.row.show"
              v-model="scope.row.newSceneType"
              :data="newScene"
              placeholder="新场景类型"
              title=""
              :props="{
                id: 'sceneType',
                label: 'sceneTypeName'
              }"
              clearable
            ></e6-vr-select>
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <!-- 选择场景 -->
    <select-scene
      :unknownScene="unknownScene"
      @handleClose="handleClose"
      @getData="getData"
      :addType="1"
      :corpId="corpInfo.corpId"
      :selectSceneDialog="selectSceneDialog"
    ></select-scene>
  </div>
</template>

<script>
import base from "@/mixins/base";
import SelectScene from "@/components/workOrderManage/selectScene.vue";
import minxins from "../minxins";
import {
  getSceneNameList,
  getSceneNumber,
  checkCreateTask,
  createReinstall
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "reinstall",
  components: { SelectScene },
  data() {
    return {
      loading: false,
      dialogLoading: false,
      isConfirm: 0,
      unknownScene: true, //是否需要未知场景
      newScene: [], //新场景类型
      addContactVisible: false
    };
  },
  props: [
    "corpInfo",
    "serviceTypeForm",
    "callType",
    "customerNumber",
    "mainUniqueId",
    "serverDetailsForm",
    "writeForm"
  ],
  mixins: [base, minxins],
  created() {
    this.getNewScene();
  },
  watch: {
    // 监听客户名称更改
    "corpInfo.corpId": {
      immediate: true,
      handler(val, oldVal) {
        if (val !== "" && val != oldVal) {
          this.tableData = [];
        }
      }
    }
  },
  methods: {
    /**
     删除任务项
     */
    handleDelete() {
      if (!this.deleteData.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      let tableData = _.cloneDeep(this.tableData);
      let deleteData = _.cloneDeep(this.deleteData);
      this.tableData = tableData.filter(v =>
        deleteData.every(n => {
          return !(n.sceneId == v.sceneId && n.terminalId == v.terminalId);
        })
      );
      this.deleteData = [];
      this.$refs.multipleTable.clearSelection();
      this.getData([]);
    },
    /**
     * 主机禁止删除
     */
    selectable(row) {
      if (row.virtualType != 1) {
        return false;
      } else {
        return true;
      }
    },
    //获取新场景类型
    async getNewScene() {
      try {
        this.dialogLoading = true;
        let res = await getSceneNumber({ corpId: this.corpInfo.corpId });
        this.newScene = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取场景类型
    async getSceneType() {
      try {
        let res = await getSceneNameList();
        this.sceneTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //创建重装任务单
    toTaskOrder() {
      //转服务单，转任务单
      if (!this.tableData.length) {
        this.$message.warning("请至少选择一个任务项");
        return;
      }
      //转重装任务单
      this.checkCreateTaskReq();
    },
    //校验工作中的任务项
    async checkCreateTaskReq() {
      try {
        this.$emit("setLoading", true);
        let sceneName = this.tableData.map(item => {
          return item.sceneName;
        });
        let res = await checkCreateTask({
          corpId: this.corpInfo.corpId,
          sceneName,
          workSecondClass: "3",
          terminalIdList: this.tableData.map(item => {
            return item.terminalId;
          })
        });
        this.unSceneName = res.data.sceneName;
        let arrJoin = _.cloneDeep(this.unSceneName).join(",");
        let filterSceneName = [
          ...new Set(
            _.cloneDeep(this.tableData).map(item => {
              return item.sceneName;
            })
          )
        ];
        if (this.unSceneName.length) {
          if (this.unSceneName.length < filterSceneName.length) {
            this.$emit("setLoading", false);
            let str =
              "场景" + arrJoin + "正在作业中，是否确定过滤掉【作业中】的场景？";
            this.$confirm(str, "确认提交", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                this.createReinstallReq();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消"
                });
              });
          }
          if (this.unSceneName.length == filterSceneName.length) {
            this.$emit("setLoading", false);
            this.$message.warning(`${arrJoin}正在作业中，没有可创建的任务项`);
            return;
          }
        } else {
          this.createReinstallReq();
        }
      } catch (error) {
        printError(error);
      }
    },
    //创建重装任务单请求
    async createReinstallReq() {
      try {
        this.$emit("setLoading", true);
        let res = await createReinstall({
          ...this.writeForm,
          corpId: this.corpInfo.corpId,
          corpName: this.corpInfo.corpName,
          taskItemReloadReqVOList: this.tableData
        });

        if (res.code == "OK") {
          this.addServiceRecordReq(
            this.writeForm.expecttime,
            this.serverDetailsForm.serverDetails,
            res.data.id
          );
        }
      } catch (error) {
        printError(error);
      } finally {
        this.$emit("setLoading", false);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
}
/deep/.edit-address {
  .el-form-item__label {
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.write-box {
  /deep/.el-form {
    .e6-vr-select {
      width: 260px !important;
    }
    .el-form-item {
      .el-input {
        width: 260px;
      }
      .el-textarea {
        width: 400px;
      }
    }
  }
}
</style>
